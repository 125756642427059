<template>
  <div class="text-center">
    <v-icon size="50" color="#333333" class="pb-5">{{ icon }}</v-icon>
    <h5 class="text-h5 text-capitalize pb-3">{{ title }}</h5>
    <div v-for="item in items" :key="item.id">
      <span class="text-body-2">
        <a
          v-if="item.link != ''"
          :href="item.link"
          target="_blank"
          rel="noopener noreferrer"
          class="font-weight-light text-decoration-none"
          v-html="item.text"
        >
        </a>
        <span
          v-else
          v-html="item.text"
          class="font-weight-light text-decoration-none"
        ></span>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeContact",
  props: ["title", "icon", "items"],
};
</script>

<style>
a:hover {
  color: rgb(101, 101, 101) !important;
}
span > p {
  margin: 0px !important;
}
</style>
