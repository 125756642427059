<template>
  <div id="homeSlide" class="home-slide">
    <video loop="true" muted autoplay preload="auto">
      <source
        :src="require('/public/resources/videoSlide.mp4')"
        type="video/mp4"
      />
    </video>
    <v-layout justify-center align-center column>
      <!-- prettier-ignore -->
      <!-- <div style="position: absolute; bottom: 30%; opacity: 0.5">
        <v-icon color="white" size="300">ri-play-circle-line</v-icon>
      </div> -->
      <div style="position: absolute; bottom: 20px">
        <v-img
          height="44"
          width="200"
          :src="require('/public/logo_porce_partners.svg')"
          class="logo-partners"
          :class="$vuetify.breakpoint.mdAndDown ? 'mx-auto' : ''"
        ></v-img>
      </div>
    </v-layout>
  </div>
</template>

<script>
export default {
  name: "HomeSlide",
};
</script>

<style scoped>
.home-slide {
  position: relative;
  height: 100vh;
  overflow: hidden;
}
video {
  height: 100vh;
  width: 100vw;
  object-fit: cover;
  position: absolute;
}
.logo-partners {
  filter: brightness(0) invert(1);
}
</style>
