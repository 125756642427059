<template>
  <div id="homeLocation">
    <v-container fluid class="section">
      <v-row>
        <v-col
          cols="12"
          md="6"
          :style="$vuetify.breakpoint.mdAndUp ? 'padding-top: 150px' : ''"
        >
          <h4 class="title_section text-capitalize">
            {{ $t("location") }}
          </h4>
          <div
            class="body-2 body_intro"
            :style="$vuetify.breakpoint.mdAndUp ? 'max-width: 350px' : ''"
          >
            <p v-html="$t('location_info')"></p>
          </div>
        </v-col>
        <v-col cols="12" md="6" class="d-flex">
          <v-img :src="require('/public/resources/imgLocation.jpeg')"></v-img>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "HomeLocation",
};
</script>

<style scoped>
.body_intro {
  display: block;
  font-size: 0.85rem !important;
}
</style>
