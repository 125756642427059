import Vue from "vue";

/*TITLE*/
document.title = "Residencial Villa Gaura | Porcelanosa Partners ADVANCE";

/*GALLERY*/
Vue.prototype.$exit_gallery_livingroom = true;
Vue.prototype.$exit_gallery_kitchen = true;
Vue.prototype.$exit_gallery_bath1 = true;
Vue.prototype.$exit_gallery_bath2 = true;
Vue.prototype.$exit_gallery_bedroom = true;
Vue.prototype.$exit_gallery_others = true;

Vue.prototype.$image_livingroom = "salon--20240902090903.jpg";
Vue.prototype.$image_kitchen = "cocina-copia--20240902090915.jpg";
Vue.prototype.$image_bath1 = "banyo-2--20240902090930.jpg";
Vue.prototype.$image_bath2 = "bano-sec--20240902090944.jpg";
Vue.prototype.$image_bedroom = "dormitorio-ppal-copia--20240902090901.jpg";
Vue.prototype.$image_others = "af_af_ext_03-copia--20240902090914.jpg";

Vue.prototype.$gallery_image_livingroom = [
  {
    src: "salon--20240902090903.jpg",
  },
];

Vue.prototype.$gallery_image_kitchen = [
  {
    src: "cocina-copia--20240902090915.jpg",
  },
];

Vue.prototype.$gallery_image_bath1 = [
  {
    src: "banyo-2--20240902090930.jpg",
  },
];

Vue.prototype.$gallery_image_bath2 = [
  {
    src: "bano-sec--20240902090944.jpg",
  },
];

Vue.prototype.$gallery_image_bedroom = [
  {
    src: "dormitorio-ppal-copia--20240902090901.jpg",
  },
];

Vue.prototype.$gallery_image_others = [
  {
    src: "af_af_ext_03-copia--20240902090914.jpg",
  },
];

/*ALL GALLERIES*/
Vue.prototype.$exit_all_galleries = true;
Vue.prototype.$all_galleries = [
  {
    src: "salon--20240902090903.jpg",
  },
  {
    src: "cocina-copia--20240902090915.jpg",
  },
  {
    src: "banyo-2--20240902090930.jpg",
  },
  {
    src: "bano-sec--20240902090944.jpg",
  },
  {
    src: "dormitorio-ppal-copia--20240902090901.jpg",
  },
  {
    src: "af_af_ext_03-copia--20240902090914.jpg",
  },
];

/*TYPOLOGIES*/
Vue.prototype.$exit_typologies = true;
Vue.prototype.$typologies = [
  {
    id: "57",
    name: "Vivienda 2 dormitorios",
    flat_m2: "77",
    flat_roof_m2: "1",
    bedroom_m2: "2",
    bathroom_m2: "2",
    blueprint: "special-2-bedroom-groundfloor-model--20240902090953.pdf",
  },
  {
    id: "58",
    name: "Vivienda 3 dormitorios especial",
    flat_m2: "148.36",
    flat_roof_m2: "2",
    bedroom_m2: "3",
    bathroom_m2: "3",
    blueprint: "special-3-bedroom-model-on-corner--20240902090956.pdf",
  },
  {
    id: "59",
    name: "Vivienda 3 dormitorios",
    flat_m2: "112.89",
    flat_roof_m2: "2",
    bedroom_m2: "3",
    bathroom_m2: "2",
    blueprint: "standard-3-bedroom-model--20240902090901.pdf",
  },
];

/*VIDEO 3DFILM*/
Vue.prototype.$video_3dfilm = "";

/*CATALOG*/
Vue.prototype.$catalog = true;

/*ADDRESS*/
Vue.prototype.$address = [
  {
    link: "https://maps.google.com/?q=https:%20maps%20app%20goo%20gl%20ddvyesizj2mjviawa%20<br>%20calle%20josé%20lópez%20portillo%2012%2030709%20roldán",
    text: "https://maps.app.goo.gl/ddVYEsiZJ2mJviaWA <br> Calle José López Portillo 12, 30709 Roldán",
  },
];

/*CONTACT*/
Vue.prototype.$contact = [
  {
    link: "telf:+34 604 477 196",
    text: "+34 604 477 196",
  },
  {
    link: "mailto:info@villagaura.com",
    text: "info@villagaura.com",
  },
  {
    link: "https://villagaura.com/",
    text: "Villa Gaura",
  },
];

/*SCHEDULE*/
Vue.prototype.$schedule = [
  {
    link: "",
    text: "<p>Monday - Friday: 10u-14u and 14u30-18u30</p><p>Saturday 10u-15u</p><p>Outside these hours possible by appointment</p>",
  },
];

