<template>
  <v-layout>
    <v-app-bar
      app
      :color="$vuetify.breakpoint.mdAndUp ? bgWhite : bg"
      hide-on-scroll
      elevate-on-scroll
      :height="$vuetify.breakpoint.mdAndUp ? '125px' : ''"
    >
      <v-app-bar-nav-icon
        dark
        @click="drawer = true"
        class="hidden-md-and-up"
      ></v-app-bar-nav-icon>
      <v-container fluid class="hidden-sm-and-down no-padding">
        <v-row no-gutters class="align-center">
          <v-col
            cols="5"
            class="d-flex"
            :class="
              $vuetify.breakpoint.lgAndUp
                ? 'justify-end'
                : 'justify-space-around'
            "
          >
            <v-btn
              v-for="item in getItemsStart"
              :key="item.value"
              text
              @click="$vuetify.goTo('#' + item.goTo, options)"
              :class="[{ menu_btn: $vuetify.breakpoint.lgAndUp }]"
            >
              {{ $t(item.name) }}
            </v-btn>
          </v-col>
          <v-col cols="2">
            <v-layout align-start justify-center>
              <v-img
                max-height="200"
                max-width="125"
                :src="require('/public/resources/logotype.png')"
                @click="$vuetify.goTo('#homeSlide', options)"
                style="cursor: pointer"
              ></v-img>
            </v-layout>
          </v-col>
          <v-col
            cols="5"
            class="d-flex"
            :class="
              $vuetify.breakpoint.lgAndUp
                ? 'justify-start'
                : 'justify-space-around'
            "
          >
            <v-btn
              v-for="item in getItemsEnd"
              :key="item.value"
              text
              @click="$vuetify.goTo('#' + item.goTo, options)"
              :class="[{ menu_btn: $vuetify.breakpoint.lgAndUp }]"
            >
              {{ $t(item.name) }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" fixed temporary>
      <v-list nav dense>
        <v-list-item-group>
          <v-list-item v-for="(item, index) in getItems" :key="item.value">
            <v-list-item-title @click="tab = index">
              <v-btn
                text
                @click="$vuetify.goTo('#' + item.goTo, options)"
                style="font-weight: bold"
              >
                {{ item.name }}
              </v-btn>
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </v-layout>
</template>

<script>
export default {
  name: "AppNavigation",
  data() {
    return {
      duration: 1000,
      offset: 5,
      drawer: false,
      bg: "transparent",
      bgWhite: "white",
      itemsMenuStart: ["location", "theProject"],
      itemsMenuEnd: ["catalog", "contact"],
      itemsMenu: [
        {
          name: "home",
          goTo: "homeSlide",
          content: true,
        },
        {
          name: "location",
          goTo: "homeLocation",
          content: true,
        },
        {
          name: "theProject",
          goTo: "homeCarousel",
          content: this.$exit_all_galleries,
        },
        {
          name: "catalog",
          goTo: "HomeCatalogue",
          content: this.$catalog,
        },
        {
          name: "contact",
          goTo: "homeContact",
          content: true,
        },
      ],
    };
  },
  computed: {
    options() {
      return {
        duration: this.duration,
        offset: this.offset,
      };
    },
    getItems: function () {
      let items = [];
      this.itemsMenu.forEach((element) => {
        if (element.name) items.push(element);
      });
      return items;
    },
    getItemsStart: function () {
      let items = [];
      this.itemsMenu.forEach((element) => {
        if (this.itemsMenuStart.includes(element.name)) items.push(element);
      });
      return items;
    },
    getItemsEnd: function () {
      let items = [];
      this.itemsMenu.forEach((element) => {
        if (this.itemsMenuEnd.includes(element.name)) items.push(element);
      });
      return items;
    },
  },
  mounted() {
    window.onscroll = () => {
      this.changeColor();
    };
  },
  methods: {
    changeColor() {
      if (
        document.body.scrollTop > 100 ||
        document.documentElement.scrollTop > 100
      ) {
        this.bg = "rgba(0, 0, 0, 0.5)";
      } else {
        this.bg = "transparent";
      }
    },
  },
};
</script>
<style scoped>
.no-padding {
  padding: 0px !important;
}
.bg-white {
  background: white !important;
}
.menu_btn {
  margin: 0px 2rem;
}
</style>
