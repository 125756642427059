<template>
  <div id="homeTypology">
    <v-container fluid class="section">
      <v-row>
        <v-col cols="12">
          <header-section :title="$t('typology')"></header-section>
        </v-col>
        <v-col>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-data-table
                  :headers="headers"
                  :items="this.$typologies"
                  hide-default-footer
                >
                  <template
                    v-for="head in headers"
                    #[`header.${head.value}`]="{ header }"
                  >
                    <span class="text-uppercase" :key="head.value">
                      {{ header.text }}
                    </span>
                  </template>

                  <template #[`item.name`]="{ item }">
                    <span class="text-uppercase">
                      {{ item.name }}
                    </span>
                  </template>

                  <template #[`item.flat_m2`]="{ item }">
                    <span v-if="item.flat_m2">
                      {{ item.flat_m2 }} m<sup>2</sup>
                    </span>
                    <span v-else>-</span>
                  </template>

                  <template #[`item.flat_roof_m2`]="{ item }">
                    <span v-if="item.flat_roof_m2">
                      {{ item.flat_roof_m2 }} m<sup>2</sup>
                    </span>
                    <span v-else>-</span>
                  </template>

                  <template #[`item.bedroom_m2`]="{ item }">
                    <span v-if="item.bedroom_m2">
                      {{ item.bedroom_m2 }}
                    </span>
                    <span v-else>-</span>
                  </template>

                  <template #[`item.bathroom_m2`]="{ item }">
                    <span v-if="item.bathroom_m2">
                      {{ item.bathroom_m2 }}
                    </span>
                    <span v-else>-</span>
                  </template>

                  <template #[`item.blueprint`]="{ item }">
                    <v-btn
                      small
                      text
                      :href="'/resources/' + item.blueprint"
                      target="_blank"
                    >
                      {{ $t("seeBlueprint") }}
                    </v-btn>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { i18n } from "@/plugins/i18n";
import HeaderSection from "../components/HeaderSection";

export default {
  name: "HomeTypology",
  components: {
    HeaderSection,
  },
  data() {
    return {
      headers: [
        {
          text: i18n.t("name"),
          align: "center",
          sortable: false,
          value: "name",
        },
        {
          text: i18n.t("flat"),
          align: "center",
          sortable: false,
          value: "flat_m2",
        },
        {
          text: i18n.t("flatRoof"),
          align: "center",
          sortable: false,
          value: "flat_roof_m2",
        },
        {
          text: i18n.t("bedrooms"),
          align: "center",
          sortable: false,
          value: "bedroom_m2",
        },
        {
          text: i18n.t("bathrooms"),
          align: "center",
          sortable: false,
          value: "bathroom_m2",
        },
        {
          text: i18n.t("blueprint"),
          align: "center",
          sortable: false,
          value: "blueprint",
        },
      ],
    };
  },
};
</script>

<style>
thead.v-data-table-header > tr > th {
  color: #000000 !important;
  border-bottom-color: #000000 !important;
}
</style>
