<template>
  <div id="homeCarousel">
    <v-carousel
      cycle
      hide-delimiters
      show-arrows-on-hover
      :height="$vuetify.breakpoint.mdAndUp ? '850px' : '725px'"
    >
      <template v-slot:prev="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-icon size="100">ri-play-reverse-fill</v-icon>
        </v-btn>
      </template>
      <template v-slot:next="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-icon size="100">ri-play-fill</v-icon>
        </v-btn>
      </template>
      <v-carousel-item
        v-for="(item, i) in this.$all_galleries"
        :key="i"
        :src="'/resources/' + item.src"
      ></v-carousel-item>
    </v-carousel>
  </div>
</template>

<script>
export default {
  name: "HomeCarousel",
  components: {},
};
</script>

<style>
.v-window__prev,
.v-window__next {
  background: transparent !important;
}
</style>
