import Vue from "vue";
import VueI18n from "vue-i18n";
Vue.use(VueI18n);
Vue.use(VueI18n);
const messages = {
  es: {
    home: "home",
    theProject: "el proyecto",
    location: "localización",
    typology: "tipología",
    typologySubtitle: "",
    name: "nombre",
    flat: "vivienda const.",
    flatRoof: "terraza",
    bedrooms: "dormitorios",
    bathrooms: "baños",
    blueprint: "plano",
    seeBlueprint: "ver plano",
    film: "3D film",
    filmSubtitle: "Experiencia audiovisual del interior<br>de la vivienda con la que ver los<br>ambientes con vida.",
    catalog: "catálogo",
    catalogSubtitle: "Catálogo para ver la presentación<br>de la promoción residencial y elegir<br>acabados.",
    download: "descargar",
    contact: "contacto",
    address: "dirección",
    speakUs: "hablemos",
    schedule: "horario",
    allRightsReserved: "Todos los derechos reservados",
    websiteTitle: "Residencial Villa Gaura",
    websiteAddress: "https://maps.app.goo.gl/ddVYEsiZJ2mJviaWA <br> Calle José López Portillo 12, 30709 Roldán",
    websiteWebName: "Villa Gaura",
    websiteWebLink: "https://villagaura.com/",
    websitePhone: "+34 604 477 196",
    websiteEmail: "info@villagaura.com",
    location_info: "<p>Roldán es una pedanía de Torre-Pacheco que se encuentra ubicada en el área geográfica conocida como ‘el campo de Cartagena’, situado en el centro oeste del término municipal de Torre-Pacheco, a 8 km de la cabecera del municipio. Dista 34 km de la ciudad de Murcia y 28 km. de Cartagena.</p>",
    opening_hours: "<p>Monday - Friday: 10u-14u and 14u30-18u30</p><p>Saturday 10u-15u</p><p>Outside these hours possible by appointment</p>",
  },
  en: {
    home: "home",
    theProject: "the project",
    location: "location",
    typology: "typology",
    typologySubtitle: "",
    name: "name",
    flat: "constructed house",
    flatRoof: "terrace",
    bedrooms: "bedrooms",
    bathrooms: "baños",
    blueprint: "flat",
    seeBlueprint: "see blueprint",
    film: "3D film",
    filmSubtitle: "Audiovisual experience of the interior <br> of the house with which you can see <br> the animated environments",
    catalog: "catalog",
    catalogSubtitle: "Catalog to view the presentation <br> of the residential development and <br> choose finishes",
    download: "download",
    contact: "contact",
    address: "address",
    speakUs: "speak us",
    schedule: "schedule",
    allRightsReserved: "All rights reserved",
    websiteTitle: "",
    websiteAddress: "",
    websiteWebName: "",
    websiteWebLink: "",
    websitePhone: "",
    websiteEmail: "",
    location_info: "",
    opening_hours: "",
  },
};
export const i18n = new VueI18n({
   locale: window.navigator.language.split("-")[0],
   fallbackLocale: "en",
   messages,
});

