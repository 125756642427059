<template>
  <div id="homeContact" class="section_background_end">
    <v-container fluid class="section_footer">
      <v-row>
        <v-col>
          <header-section :title="$t('contact')"></header-section>
        </v-col>
      </v-row>
      <v-row>
        <v-container>
          <v-row>
            <v-col cols="12" md="4">
              <!-- prettier-ignore -->
              <contact-section
                v-if="this.$address != ''"
                icon="ri-map-pin-line"
                :title="$t('address')"
                :items="this.$address"
              ></contact-section>
            </v-col>
            <v-col cols="12" md="4">
              <!-- prettier-ignore -->
              <contact-section
                v-if="this.$contact != ''"  
                icon="ri-mail-send-line"
                :title="$t('speakUs')"
                :items="this.$contact"
              ></contact-section>
            </v-col>
            <v-col cols="12" md="4">
              <!-- prettier-ignore -->
              <contact-section
                v-if="this.$schedule != ''"
                icon="ri-information-line"
                :title="$t('schedule')"
                :items="this.$schedule"
              ></contact-section>
            </v-col>
          </v-row>
        </v-container>
      </v-row>
      <v-row justify="center">
        <div class="justify-center mt-5">
          <v-img
            max-height="200"
            max-width="250"
            :src="require('/public/prince.png')"
            class="mx-auto"
          ></v-img>
          <p style="font-size: 12px" class="text-center">
            <b>
              &copy; {{ new Date().getFullYear() }} PORCELANOSA Grupo A.I.E.
            </b>
            <br v-if="$vuetify.breakpoint.xsOnly" />
            {{ $t("allRightsReserved") }}
          </p>
        </div>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import HeaderSection from "../components/HeaderSection";
import ContactSection from "../components/ContactSection";

export default {
  name: "HomeContact",
  components: {
    HeaderSection,
    ContactSection,
  },
};
</script>

<style scoped></style>
