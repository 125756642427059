<template>
  <div>
    <home-slide />
    <home-location />
    <home-carousel v-if="this.$exit_all_galleries" />
    <home-typology v-if="this.$exit_typologies" />
    <!-- <home-3DFilm v-if="this.$video_3dfilm" /> -->
    <home-catalogue v-if="this.$catalog" />
    <home-contact />
  </div>
</template>

<script>
import HomeSlide from "../components/HomeSlide";
import HomeLocation from "../components/HomeLocation";
import HomeCarousel from "../components/HomeCarousel";
import HomeTypology from "../components/HomeTypology";
// import Home3DFilm from "../components/Home3DFilm";
import HomeCatalogue from "../components/HomeCatalogue";
import HomeContact from "../components/HomeContact";

export default {
  name: "HomeView",

  components: {
    HomeSlide,
    HomeLocation,
    HomeCarousel,
    HomeTypology,
    // Home3DFilm,
    HomeCatalogue,
    HomeContact,
  },
};
</script>

<style scoped></style>
